import React, { useState } from "react";
import { Helmet } from "react-helmet";
import HomeHeader from "../../components/header/landing/HomeHeader";
import Social from "../../components/social/Social";
import Portfolio from "../../components/portfolio/Portfolio";
import FaqFour from "../../components/faq/FaqFour";
import Blog from "../../components/form-survey-landing/Blog";
import Tilt from "react-parallax-tilt";
import HeroBannerForBrand from "../../components/hero-banner/HeroBannerForBrand";
import HeroAbout from "../../components/hero-banner/HeroAbout";
import StatisticsCounter from "../../components/counter/StatisticsCounter";
import HomePricing from "../../components/pricing/pricing-four/HomePricing";
import HomeFooter from "../../components/footer/HomeFooter";
import HomeCopyRight from "../../components/footer/HomeCopyRight";
import SmartStoreVideo from "../../elements/SmartStoreVideo";
import { IoCheckmarkSharp } from "react-icons/io5";
import ModalStartNowForm from "../../components/modal-form/ModalStartNowForm";
import { Link } from "react-router-dom";

const Home = () => {
	// For header select menu
	const [isModalOpen, setModalOpen] = useState(false)

	function toggleModalOne() {
		setModalOpen(!isModalOpen);
	}

	return (
		<div className="main-page-wrapper p0 font-gordita">
			<Helmet>
				<title>
					Paylinkshop - Social Commerce Platform
				</title>
			</Helmet>
			<HomeHeader toggleModal={toggleModalOne}/>
			<div id="about">
				<HeroAbout toggleModal={toggleModalOne}/>
			</div>
			<div className="fancy-feature-twentyThree pt-50 pb-10 md-pt-100" id="how-it-works" style={{ backgroundColor: '#f7f7f7' }}>
				<div className="container">
					<div className="block-style-twentyThree">
						<div className="row">
							<div
								className="col-lg-7 order-lg-last ms-auto"
								data-aos="fade-left"
								data-aos-duration="1200"
							>
								<div
									className="screen-container ms-auto"
								>
									<SmartStoreVideo/>
								</div>
							</div>
							<div
								className="col-lg-5 order-lg-first"
								data-aos="fade-right"
								data-aos-duration="1200"
							>
								<div className="text-wrapper">
									<h3 className="title">Effortless Store Creation in no time!</h3>
									<h3 className="subtitle first"><b>Install</b></h3>
									<p>
										Get started easily by installing our Shopify app and we do the rest.
									</p>
									<h3 className="subtitle"><b>Create</b></h3>
									<p>
										instantly create stores for each post with all your products.
									</p>
									<h3 className="subtitle"><b>Share</b></h3>
									<p>
										Invite influencers to join and empower them to create their own stores effortlessly.
									</p>
								</div>
							</div>
						</div>
					</div>

					<div className="block-style-twentyThree">
						<div className="row">
							<div className="col-lg-7">
								<Tilt>
									<div
										className="screen-container me-auto"
										data-aos="fade-right"
										data-aos-duration="1200"
									>
										<img
											src="images/assets/fast_checkout_image_original.png"
											alt="screen"
											className="shapes shape-two"
										/>
									</div>
								</Tilt>
							</div>
							<div
								className="col-lg-5 ms-auto"
								data-aos="fade-left"
								data-aos-duration="1200"
							>
								<div className="text-wrapper">
									<h3 className="title">Unique experience for every user</h3>
									<p className="subsection">
										<IoCheckmarkSharp className="check-v-mark" />
										Elevate sales and revenue with our exclusive store generation, tailored and personalized for each user.
									</p>
									<p className="subsection">
										<IoCheckmarkSharp className="check-v-mark" />
										Experience a personalized approach that maximizes sales potential and enhances user engagement.
									</p>
								</div>
							</div>
						</div>
					</div>

					<div className="block-style-twentyThree">
						<div className="row">
							<div
								className="col-lg-7 order-lg-last ms-auto"
								data-aos="fade-left"
								data-aos-duration="1200"
							>
								<div className="screen-container ms-auto">
									<Tilt>
										<img
											src="images/assets/social_medias_image.png"
											alt="screen"
											className="shapes shape-three"
										/>
									</Tilt>
								</div>
							</div>
							<div
								className="col-lg-5 order-lg-first"
								data-aos="fade-right"
								data-aos-duration="1200"
							>
								<div className="text-wrapper">
									<h3 className="title">
										All your favorite social media
									</h3>
									<p className="subsection">
										<IoCheckmarkSharp className="check-v-mark" />
										Share across multiple social media platforms effortlessly and efficiently.
									</p>
									<p className="subsection">
										<IoCheckmarkSharp className="check-v-mark" />
										Manage all data and interactions from different platforms in a centralized, easy-to-use interface.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<HeroBannerForBrand />

			<div className="counter-style-three lg-container mt-130">
				<div className="container">
					<div className="bottom-border">
						<StatisticsCounter />
					</div>
				</div>
			</div>

			<div className="useable-tools-section-two bg-shape mb-200 md-mb-90">
				<div className="bg-wrapper">
					<div className="shapes shape-one"></div>
					<div className="shapes shape-two"></div>
					<div className="shapes shape-three"></div>
					<div className="shapes shape-four"></div>
					<div className="container">
						<div className="title-style-two text-center mb-70 md-mb-10">
							<div className="col-lg-10 col-md-11 m-auto">
								<h2>
									For{" "}
									<span>
										every
										<img src="images/shape/line-shape-2.svg" alt="" />
									</span>
									{" "}SOCIAL
								</h2>
							</div>
						</div>

						<div className="icon-wrapper">
							<Social />
						</div>
					</div>
				</div>
			</div>

			<div className="fancy-portfolio-one md-pt-100" id="portfolio">
				<div className="container">
					<div className="title-style-two">
						<h2>
							We love our clients, and they love us
						</h2>
					</div>
				</div>

				<div className="portfolioSliderOne arrow-top-right pt-120 md-pt-70">
					<Portfolio />
				</div>
			</div>

			<div className="pricing-section-four pt-80 md-pt-100" id="pricing">
				<div className="container">
					<div className="row">
						<div className="col-xl-10  m-auto mb-20">
							<div className="title-style-six text-center">
								<h3>
									Maximize your growth, choose the right <span>plan.</span>
								</h3>
							</div>
						</div>
					</div>
				</div>

				<HomePricing toggleModal={toggleModalOne}/>
			</div>

			<div className="feature-blog-five lg-container mt-80 mb-80 md-pt-120">
				<div className="container">
					<div
						className="title-style-eleven text-center mb-40 md-mb-20"
						data-aos="fade-up"
					>
						<h2>Uncover daily insights</h2>
					</div>

					<div className="row">
						<Blog />
					</div>
					<p className="term-text term-text-see-all" data-aos="fade-up" align="center" onClick={() => {}}>
						<Link style={{textDecoration: "underline"}} to={`/blog`}>See all posts</Link>
					</p>
				</div>
			</div>

			<div className="faq-section-four" id='faq'>
				<div className="container">
					<div className="title-style-five text-center mb-80 md-mb-60">
						<h2>FAQ</h2>
					</div>

					<div className="row">
						<div className="col-xl-9 col-lg-10 m-auto">
							<FaqFour />
						</div>
					</div>
				</div>
			</div>

			<footer className="theme-footer-eight mt-100">
				<div className="top-footer">
					<div className="container">
						<HomeFooter />
					</div>
				</div>
				<div className="container">
					<div className="bottom-footer mt-50 md-mt-30">
						<HomeCopyRight />
					</div>
				</div>
			</footer>
			
			<ModalStartNowForm isOpen={isModalOpen} toggleModal={toggleModalOne}/>
		</div>
	);
};

export default Home;
