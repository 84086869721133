import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

const FaqContent = [
  {
    title: "What is Paylink?",
    desc: `Paylink is a cutting-edge social-commerce solution powered by AI designed to create a single-link experience for multiple products showcased in images or videos. It enables the creation of social shops embedded within all social network platforms, streamlining the purchasing process and significantly boosting conversion rates.`,
    expand: "a",
  },
  {
    title: "How does Paylink benefit my business?",
    desc: `With Paylink, you can efficiently manage your content creators/influencers, directly oversee content, monitor results, and track metrics. This streamlined approach simplifies sales tracking, facilitates easier content management, and translates to increased revenue.`,
    expand: "b",
  },
  {
    title: "Can Paylink help improve my conversion rates?",
    desc: `Absolutely. Paylink's seamless integration with social media platforms enhances the shopping experience, leading to improved conversion rates by up to 5X. The feature-rich platform optimizes user engagement and guides customers to complete purchases efficiently.`,
    expand: "c",
  },
  {
    title: "What features does Paylink offer?",
    desc: `Paylink offers a range of features including the ability to turn every post into a personalized store, seamlessly track performance, incorporate integrated promocodes, design customizable storefronts, create links to collections/products, work and track with influencers and more.`,
    expand: "d",
  },
  {
    title: "Is Paylink user-friendly?",
    desc: `Yes, Paylink boasts a user-friendly interface. Its intuitive design ensures ease of use for creating, managing, and optimizing social commerce strategies, making it accessible for both beginners and experienced users.`,
    expand: "e",
  },
  {
    title: "How can I get started with Paylink?",
    desc: `Getting started with Paylink is simple! Download our Shopify app. Our user-friendly interface and comprehensive guides will assist you in setting up your social commerce strategy efficiently.`,
    expand: "f",
  },
  {
    title: "Is Paylink suitable for small businesses or larger enterprises?",
    desc: `Paylink is versatile and adaptable for businesses of all sizes. Whether you're a startup or an established enterprise, our platform offers customizable solutions tailored to meet your specific social commerce needs.`,
    expand: "g",
  },
  {
    title: "Does Paylink offer customer support?",
    desc: `Absolutely. We provide dedicated customer support to assist you throughout your journey with Paylink. Our team is committed to ensuring a seamless experience and addressing any queries or issues promptly.`,
    expand: "w",
  },
  {
    title: "How secure is Paylink for transactions?",
    desc: `Security is a priority at Paylink. We employ robust security measures to ensure all transactions and data are handled with the highest level of protection. Your information and transactions are secure with us.`,
    expand: "s",
  },
  {
    title: " Is Paylink compliant with GDPR?",
    desc: `Paylink is fully compliant with General Data Protection Regulation (GDPR), UK General Data Protection Regulation (UK GDPR), and California Privacy Rights Act (CPRA).`,
    expand: "p",
  },
];

const FaqFour = () => {
  return (
    <div
      className="accordion-style-four"
      data-aos="fade-up"
      data-aos-duration="1200"
    >
      <div className="faq-wrraper">
        <Accordion /* preExpanded={["b"]} */ allowZeroExpanded>
          {
            FaqContent.map((item, i) => (
              <AccordionItem className="card" key={i} uuid={item.expand}>
                <AccordionItemHeading className="card-header">
                  <AccordionItemButton>
                    <h5 className="mb-0">
                      <button className="btn btn-link">{item.title}</button>{" "}
                    </h5>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className="card-body fadeInUp">
                  <p>{item.desc}</p>
                </AccordionItemPanel>
              </AccordionItem>
            ))
          }
        </Accordion>
      </div>
    </div>
  );
};

export default FaqFour;
