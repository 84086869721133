import React from "react";

const socialContent = [
	{
		icon: "fa-instagram",
		link: "https://www.instagram.com/paylinkshop",
	},
	{
		icon: "fa-linkedin",
		link: "https://www.linkedin.com/company/paylinkshop",
	},
];

const HomeCopyRight = () => {
	return (
		<div className="row">
			<div className="col-lg-6 order-lg-2 mb-20">
				<p className="copyright text-lg-left">
					Copyright @{new Date().getFullYear()}{" "}
					<a
						href="https://paylinkshop.com"
						target="_blank"
						rel="noopener noreferrer"
					>
						Paylink
					</a>{" "}
					ltd.
				</p>
			</div>
			<div className="col-lg-6 order-lg-3 mb-20">
				<ul
					className=" d-flex
              justify-content-center justify-content-lg-end
              social-icon"
				>
					{socialContent.map((val, i) => (
						<li key={i}>
							<a href={val.link} target="_blank" rel="noreferrer">
								<i className={`fa ${val.icon}`}></i>
							</a>
						</li>
					))}
				</ul>
			</div>
		</div>
	);
};

export default HomeCopyRight;
