import React, { useState } from "react";
import Modal from "react-modal";
import HeaderPopupForm from "../form/HeaderPopupForm";
import { IoCheckmarkSharp } from "react-icons/io5";
import './ModalStartNowForm.css'

const ModalStartNowForm = ({isOpen, toggleModal}) => {

    const [isThankYou, setThankYou] = useState(false)

    const closeModal = () => {
        toggleModal()
        window.history.replaceState(null, "home", "/")
        setTimeout(() => {
            setThankYou(false)
        }, 250)
    }

    return <>
        <Modal
            isOpen={isOpen}
            onRequestClose={closeModal}
            contentLabel="My dialog"
            className="custom-modal  modal-contact-popup-one"
            overlayClassName="custom-overlay"
            closeTimeoutMS={500}
            ariaHideApp={false}
        >
            <div className="box_inner ">
            <main className="main-body box_inner modal-content clearfix">
                <button className="close" onClick={closeModal}>
                    <img src="images/icon/close.svg" alt="close"/>
                </button>
                <div className="left-side">
                    <div className="d-flex flex-column justify-content-between h-100">
                        <div className="row">
                            <blockquote>
                                Experience the Distinction
                            </blockquote>
                            <span className="bio">
										<IoCheckmarkSharp className="check-v-mark" /> Enhance sales channels across all platforms.</span>
                            <span className="bio">
										<IoCheckmarkSharp className="check-v-mark" /> Transform influencers into sales channels</span>
                            <span className="bio">
										<IoCheckmarkSharp className="check-v-mark" /> Boost conversion rates and increase orders value</span>
                        </div>
                        <div className="row bottom-section">
                            <span className="title">
                                Join Paylink customers
                            </span>
                            <img
                                src="images/assets/contact-us-logos.png"
                                alt=""
                                style={{maxWidth: '90%', maxHeight: '60px', height: 'auto', margin: 'auto'}}
                            />
                        </div>
                    </div>
                </div>

                <div className="right-side">
                    {isThankYou ? 
                        <img alt="thank-you" data-aos="fade-left" src="images/gifs/thank_you.gif" style={{width: '100%', height: '100%'}}/> :
                        <>
                            <h2 className="form-title">Let's talk</h2>
                            <h3 className="form-subtitle">One of our experts will contact you soon</h3>
                            <HeaderPopupForm setThankYou={setThankYou}/>
                        </>}
                </div>
            </main>
            </div>
        </Modal>
    </>
};

export default ModalStartNowForm;